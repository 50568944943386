@mixin header {
  @extend %clearfix;

  .site-header-col-1 {
    float: left;
    display: flex;
    align-items: center;

    a.logo {
      font-family: $base-font-family;
      font-style: italic;
      font-weight: 700;
      line-height: 40px;
      font-size: 24px;
      color: $brand-color;
    }
    a.button {
      display: none;
    }

    section.search {
      margin-left: $spacing-unit / 2;
      @extend %search;
    }
  }

  .site-header-col-2 {
    float: right;

    a.links {
      font-family: $label-font-family;
      border-radius: 3px;
      padding: 10px 17px 11px;
      display: inline-block;
      font-size: 14px;
      line-height: 1;
    }

    a.signup {
      $bkg: $accent-color;
      $border: darken($bkg, 10%);

      color: white;
      margin: 0 $spacing-unit / 2 0 0;
      border: 2px solid $border;
      background-color: $bkg;

      &:hover {
        background-color: darken($bkg, 5%);
      }

      &:active {
        border-color: darken($border, 15%);
        background-color: darken($bkg, 15%);
      }
    }

    a.login {
      $bkg: lighten($grey-color-light, 15%);
      $border: darken($bkg, 10%);

      color: $secondary-text-color;
      border: 2px solid $border;
      background-color: $bkg;

      &:hover {
        background-color: darken($bkg, 3%);
      }

      &:active {
        border-color: darken($border, 5%);
        background-color: darken($bkg, 5%);
      }
    }
  }
}

@mixin header-mobile {
  padding: 0;

  .wrapper {
    padding: 0;
  }
  .site-header-col-1,
  .site-header-col-2 {
    float: none;
    text-align: center;
  }
  .site-header-col-2 {
    display: none;
  }
  .site-header-col-1 {
    display: block;

    span.menu-header {
      display: flex;
      padding: $spacing-unit / 2;
      justify-content: space-between;
      align-items: center;

      a.logo {
        line-height: 1;
      }

      a.button {
        padding: 0 10px;
        border: 1px solid $divider-color;
        border-radius: 3px;
        display: block;

        &:active {
          background-color: $divider-color;
          border-color: transparent;
        }
        &:visited {
          color: $link-color;
        }
        i.fa-caret-up {
          display: none;
        }
      }
    }

    section.search {
      margin: 0;
      display: none;
    }
  }

  &.expanded {
    .site-header-col-1 {
      span.menu-header {
        border-bottom: 1px solid $divider-color;

        a.button {
          i.fa-caret-down {
            display: none;
          }
          i.fa-caret-up {
            display: inline-block;
          }
        }
      }
      section.search {
        text-align: left;
        display: inline-block;
      }
    }
    .site-header-col-2 {
      padding-bottom: 7px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-align: left;
      background-color: $divider-color-light;

      a.links {
        order: 3;
        padding-top: $spacing-unit / 2;
        padding-bottom: $spacing-unit / 2;
        font-size: 16px;
      }

      a.login,
      a.signup {
        margin: 0 $spacing-unit / 2 10px;
        font-size: 14px;
        order: 2;
        align-self: flex-start;
        padding-top: 12px;
        padding-bottom: 13px;
        display: inline-block;

        &.signup {
          order: 1;
          margin-top: $spacing-unit / 2;
          margin-bottom: $spacing-unit / 2;
        }
      }
    }
  }
}

%search {
  position: relative;
  font-size: 15px;
  display: inline-block;

  label {
    position: absolute;
    left: $spacing-unit / 2;
    top: 50%;
    margin-top: -12px;
    z-index: 10;
    opacity: 0.4;
    pointer-events: none;
  }

  input {
    padding: 3px 10px 3px 36px;
    height: 40px;
    width: 236px;
    color: $secondary-text-color;
    background-color: transparent;
    border: {
      width: 0 0 0 1px;
      style: solid;
      color: darken($divider-color, 2%);
    }
    transition: border-color ease-in-out .15s, background-color ease-in-out .15s;
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 1.3333333;
    box-sizing: border-box;

    &:focus {
      outline: 0;
      color: $text-color;
      border-radius: 4px;
      border: 1px solid lighten($brand-color, 5%);
    }
  }

  @include media-query($on-palm) {
    width: 100%;
    input {
      width: 100%;
      height: 44px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 0;
      border-width: 0 0 1px;
      border-color: $divider-color;
      background-color: $divider-color-light;

      &:focus {
        border-radius: 0;
        border-width: 0 0 1px;
      }
    }
    .algolia-autocomplete {
      width: 100%;
      vertical-align: bottom;
    }
    .algolia-autocomplete .ds-dropdown-menu {
      min-width: 300px;
    }
  }
}

/**
 * Site header for the lander
 */
.site-header.lander {
  padding: $spacing-unit / 2 0 $spacing-unit;

  @include header;

  .site-header-col-2 {
    a.signup {
      display: none;
    }
  }

  @include media-query($on-palm) {
    @include header-mobile;
  }
}



/**
 * Site header for pages
 */
.site-header.page {
  padding: $spacing-unit / 2 0 $spacing-unit / 2;
  margin-bottom: $spacing-unit / 2;
  border-bottom: 1px solid $grey-color-light;

  @include header;

  @include media-query($on-palm) {
    @include header-mobile;
  }
}

/*
.site-nav {
  float: right;
  line-height: 56px;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg path {
        fill: $grey-color-dark;
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}
*/



/**
 * Site footer
 */
.site-footer {
  padding-bottom: 45px;

  & > hr {
    margin-top: 0;
    height: 4px;
    border: 0;
    background: linear-gradient(to right, #975890, #EE8E2F);
  }

  @include media-query($on-palm) {
    padding-bottom: 5px;
  }
}
//
//.footer-heading {
//  font-size: 18px;
//  margin-bottom: $spacing-unit / 2;
//}

.contact-list,
.link-list {
  list-style: none;
  margin-left: 0;

  & > li+li {
    margin-top: 10px;
  }
}

.footer-col-wrapper {
  color: $tertiary-text-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;

  a.author-link {
    @extend %a-tertiary;
    font-weight: bold;
  }

  a.email-link,
  a.page-link {
    @extend %a-tertiary;

    &.jobs {
      color: darken($accent-color, 10%);

      &:hover {
        color: $accent-color;
      }
    }
  }

  .social-links {
    margin-top: 12px;
  }

  a.social-link {
    margin-left: 5px;
    @extend %a-tertiary;
    font-size: 34px;
    line-height: 1;
    display: inline-block;

    svg {
      vertical-align: -3px;
      height: 30px;
      width: 30px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .site-title {
    font-family: $base-font-family;
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    color: $tertiary-text-color;
  }

  .site-description {
    font-weight: 500;
  }

  .site-author {
    padding-top: 4px;
    a {
      @extend %a-tertiary;
    }
  }
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(40% - (#{$spacing-unit} / 2));
  width:         calc(40% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
	text-align: right;
  width: -webkit-calc(60% - (#{$spacing-unit} / 2));
  width:         calc(60% - (#{$spacing-unit} / 2));
}

@include media-query($on-palm) {
  footer > .wrapper {
    margin: 0;

    .footer-col-wrapper {
      margin: 0;
    }
    .footer-col {
      float: none;
      text-align: left;
      padding: 0;
      width: auto;

      &:first-child {
        margin-bottom: $spacing-unit - 10px;
      }
      &:last-child {
        padding-top: $spacing-unit - 10px;
        border-top: 1px solid $divider-color;
      }
    }
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;

  @include media-query($on-palm) {
    padding: $spacing-unit / 2 0;
  }

  .placeholder {
    font-family: $heading-font-family;
    font-size: 42px;
    text-align: center;
    padding: 200px 0 300px;
  }
}

.page-content .docs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @include media-query($on-palm) {
    flex-direction: column;
    align-items: stretch;
  }
}

.page-content .docs-wrapper > .post {
  flex: 1;
  min-width: 0;
}

.page-content .docs-wrapper > .sidebar {
  margin-right: $spacing-unit;
  padding-top: $spacing-unit / 2;
  flex: 0 0 220px;
  font-size: 14px;

  p {
    margin-bottom: 10px;

    &.active a {
      font-weight: 500;
    }

    &.title {
      i.fa {
        opacity: 0.5;
        font-size: 13px;
        width: 8px;

        &.fa-caret-down {
          position: relative;
          left: -2px;
          vertical-align: 1px;
          display: none;
        }
      }
      &.open {
        i.fa-caret-right {
          display: none;
        }
        i.fa-caret-down {
          display: inline-block;
        }
      }
    }
  }

  & > p {
    font-size: 15px;
  }

  ul {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 0;
    list-style: none;
    display: none;

    li {
      margin-top: 10px;
      padding-left: 20px;
      text-indent: -11px;

      &::before {
        content: "•";
        margin-right: 5px;
        color: $grey-color-light;
      }

      &.active a {
        font-weight: 500;
      }
    }

    &.open {
      display: block;
    }
  }

  @include media-query($on-palm) {
    margin-right: 0;
    border-top: 2px solid $grey-color-light;
    padding-top: $spacing-unit;
    order: 99;
  }
}

.lander-content {
  .placeholder {
    font-family: $heading-font-family;
    font-size: 42px;
    text-align: center;
    padding: 200px 0 300px;
  }
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
  padding-bottom: $spacing-unit / 2;
  border-bottom: 1px solid $grey-color-light;
}

.post-title {
  margin-bottom: 5px;
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1.3;

  @include media-query($on-laptop) {
    font-size: 32px;
  }
}
.post-title-context {
  margin-bottom: 0;
  color: $tertiary-text-color;
  font-size: 14px;
}

a.post-info {
  font-size: 14px;
  font-weight: 300;
  @extend %a-tertiary;
}

span.post-info-separator {
  font-size: 10px;
  font-weight: 300;
  color: $tertiary-text-color;
}

.post-context {
  margin-top: 20px;
  color: $tertiary-text-color;
  font-weight: 300;
  font-style: italic;
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}

.post-closing {
  margin-top: 2.5 * $spacing-unit;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid $divider-color;

  hr {
    margin-bottom: 1.5 * $spacing-unit;
    font-size: 28px;
    letter-spacing: .6em;
    display: block;
    border: 0;
    color: $tertiary-text-color;

    &:before {
      content: '...';
      display: inline-block;
      margin-left: .6em;
      color: rgba(0, 0, 0, .6);
      position: relative;
      top: -1.5 * $spacing-unit;
    }
  }

  @include media-query($on-palm) {
    margin-top: 2 * $spacing-unit;
    hr {
      margin-bottom: $spacing-unit;
    }
  }

  .controls {
    padding-top: 10px;
    border-top: 1px solid $divider-color;
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0;
      padding: 0 2px;
      font-size: 16px;
      color: $secondary-text-color;
      a {
        font-weight: 500;
      }
      i.fa {
        padding-left: 2px;
      }

      &:first-child {
        order: 2;
      }
    }

    @include media-query($on-palm) {
      display: block;

      p:last-child {
        margin-top: 5px;
      }
    }
  }
}

.post-cta {
  margin: $spacing-unit / 2 0 $spacing-unit;
  padding: $spacing-unit / 2;
  background-color: lighten($divider-color , 2%);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-query($on-palm) {
    margin-top: $spacing-unit / 2;
    display: block;
  }

  & p {
    margin-bottom: 0;
    font-size: 15px;
    color: $secondary-text-color;
  }
  & p a {
    font-weight: 500;
  }
  & a.button {
    $bkg: $accent-color;
    $border: darken($bkg, 10%);

    margin-left: 10px;
    flex: 0 0 auto;
    border-radius: 3px;
    padding: 10px 17px 11px;
    display: block;
    font-size: 16px;
    color: white;
    border: 2px solid $border;
    background-color: $bkg;

    &:hover {
      background-color: darken($bkg, 5%);
    }

    &:active {
      border-color: darken($border, 15%);
      background-color: darken($bkg, 15%);
    }

    @include media-query($on-palm) {
      margin-left: 0;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  & a.button i.fa {
    margin-left: 3px;
    font-size: 14px;
    opacity: 0.85;
  }

  &.blog {
    margin: 0 auto;
    max-width: 900px;
  }
}

.post-footer {
  @extend %clearfix;
  margin-top: 54px;

  text-align: center;

  & > a {
    @extend %a-secondary;
    font-weight: bold;
    line-height: 54px;
    display: inline-block;
    font-size: 14px;
  }

  .previous {
    float: left;
  }
  .next {
    float: right;
  }
}
