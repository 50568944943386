.post.common-error {
  margin: 0 auto;
  max-width: 900px;
}

/**
 * Header
 */
.post.common-error header a.breadcrumb {
  padding: 0 0 3px;
  display: inline-block;
}
.post.common-error header a.breadcrumb:after {
  padding-left: 4px;
  font-size: 16px;
  color: $grey-color-light;
  content: "/\00a0";
}
.post.common-error h1.post-title {
  font-size: 32px;
}
.post.common-error blockquote {
  padding: 5px $spacing-unit / 2 $spacing-unit / 2;
  text-align: left;
}
.post.common-error blockquote p:before,
.post.common-error blockquote p:after {
  content: none;
}
.post.common-error blockquote p {
  font-family: $code-font-family;
}

// Header Controls
.post.common-error .common-error-header-controls {
  margin-bottom: $spacing-unit - 5px;
  padding: 5px;
  display: flex;
  background-color: $divider-color;
  border-radius: 6px;
}
.post.common-error div.common-error-header-controls .panel {
  margin-right: 5px;
  flex: 1 1 auto;
  padding: 0 10px;
  border-radius: 3px 0 0 3px;
  background-color: $divider-color-light;
  text-align: center;
  font-size: 16px;
  color: $tertiary-text-color;
  display: flex;
  justify-content: center;
  align-items: center;
}
.post.common-error div.common-error-header-controls .panel:nth-child(2) {
  border-radius: 0;
}
.post.common-error div.common-error-header-controls .panel:last-child {
  margin-right: 0;
  border-radius: 0 3px 3px 0;
}
.post.common-error div.common-error-header-controls .panel a {
  padding: 0 5px;
  display: block;
  height: 44px;

  @include media-query($on-palm) {
    padding: 0 7px;
  }
}
.post.common-error div.common-error-header-controls .panel i.fa {
  font-size: 22px;
  line-height: 44px;
}
.post.common-error div.common-error-header-controls .panel i.fa-envelope {
  font-size: 20px;
}
.post.common-error div.common-error-header-controls .panel span {
  padding-left: 5px;
  vertical-align: 2px;

  @include media-query($on-palm) {
    display: none;
  }
}

// Errors List
.post.common-error ul.common-error-list li {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}
.post.common-error ul.common-error-list li i {
  top: 6px;
  opacity: 0.8;
  font-size: 14px;
}

