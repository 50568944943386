// Define defaults for each variable.

$base-font-family:      "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$heading-font-family:   serif !default;
$code-font-family:      monospace !default;
$base-font-size:        16px !default;
$base-font-weight:      400 !default;
$small-font-size:       $base-font-size * 0.875 !default;
$base-line-height:      1.5 !default;

$spacing-unit:          30px !default;

$text-color:            #111 !default;
$secondary-text-color:  #666 !default;
$tertiary-text-color:  	#999 !default;
$background-color:      #fdfdfd !default;
$brand-color:           #2a7ae2 !default;
$announcement-color:    #16A085 !default;
$code-background-color: #f0eaf7 !default;

$grey-color:            #828282 !default;
$grey-color-light:      lighten($grey-color, 40%) !default;
$grey-color-dark:       darken($grey-color, 25%) !default;

// Width of the content area
$content-width:         800px !default;

$on-palm:               600px !default;
$on-laptop:             800px !default;
$on-macbook:            1280px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "theme/base",
  "theme/layout",
  "theme/syntax-highlighting"
;
