/////////////////
// ABOUTE PAGE //
/////////////////
main > .wrapper > .about {

  .header {
    margin: 0 auto;
    max-width: 570px;
    text-align: center;

    h1 {
      padding-top: $spacing-unit;
      font-size: 40px;
    }
    p {
      font-size: 20px;
      color: $secondary-text-color;
      b {
        font-weight: 500;
      }
    }

    @include media-query($on-palm) {
      h1 {
        padding-top: $spacing-unit / 2;
        font-size: 34px;
      }
    }
  }

  hr {
    margin: $spacing-unit * 3 auto;
    max-width: 400px;
    border-color: darken($hero-bkg-color, 10%);

    @include media-query($on-palm) {
      margin: $spacing-unit * 1.5 auto;
    }
  }

  .investors {
    margin: 0 auto;
    text-align: center;
    max-width: 720px;

    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: $spacing-unit;
      color: $secondary-text-color;
      font-size: 17px;
      a {
        font-weight: 500;
      }
    }

    .logos {
      a {
        display: inline-block;
      }
      a:nth-child(1) {
        margin-right: $spacing-unit;
        vertical-align: -11px;
      }
      a:nth-child(3) {
        margin-left: $spacing-unit;
        img {
          margin-top: 32px;
        }
      }

      @include media-query($on-palm) {
        a {
          display: block;
        }
        a:nth-child(1) {
          margin-right: 0;
          margin-bottom: $spacing-unit / 2 + 5px;
        }
        a:nth-child(2) {
          margin-bottom: $spacing-unit / 2 + 5px;
        }
        a:nth-child(3) {
          margin-left: 0;
          img {
            margin-top: 0;
          }
        }
      }
    }

    ul {
      margin: $spacing-unit -10px 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      @include media-query($on-palm) {
        margin: $spacing-unit / 2 0 0 0;
      }

      li {
        flex: 0 0 33%;
        margin-top: $spacing-unit / 2 + 10px;
        margin-right: 10px;
        max-width: 156px;
        text-align: left;

        @include media-query($on-palm) {
          margin-right: 0;
          flex: 0 1 50%;
        }

        a {
          font-weight: 500;
          font-size: 17px;
          @include media-query($on-palm) {
            font-size: 15px;
          }
        }
        p {
          margin: 0;
          font-size: 13px;
          color: $tertiary-text-color;
        }

        &:last-child {
          color: $tertiary-text-color;
          font-weight: 500;
        }
      }
    }
  }

  .founders {
    margin: 0 auto;
    max-width: 720px;
    text-align: center;

    h3 {
      font-size: 24px;
      margin-bottom: $spacing-unit;
    }

    p {
      color: $secondary-text-color;
      font-size: 17px;
      a {
        font-weight: 500;
      }
      b {
        font-weight: 500;
      }
    }
  }

  .team {
    margin: 0 auto;
    max-width: 720px;
    text-align: center;

    .map-container {
      width: 100%;
      overflow: hidden;
    }
    .map {
      margin: 0 auto $spacing-unit / 2;
      width: 512px;
      height: 300px;
      position: relative;

      .pattern {
        opacity: 0.07;
        width: 100%;
        height: 100%;

        background: {
          position: center;
          repeat: no-repeat;
          image: $map-background;
        }
      }

      i {
        position: absolute;
        color: $accent-color;

        &.to {
          left: 143px;
          top: 78px;
        }
        &.sf {
          left: 87px;
          top: 109px;
        }
      }
    }

    h3 {
      font-size: 24px;
    }

    p {
      color: $secondary-text-color;
      font-size: 17px;
      a {
        font-weight: 500;
      }
      b {
        font-weight: 500;
      }
    }

    .members {
      a {
        img {
          margin: 10px;
          width: 96px;
          height: 96px;
          border-radius: 100%;
          filter: grayscale(100%);

          @include media-query($on-palm) {
            margin: 6px;
            width: 64px;
            height: 64px;
          }
        }
        &:hover img {
          filter: none;
        }
      }
    }
  }

  .community {
    margin: 0 auto;
    max-width: 720px;
    text-align: center;

    h3 {
      font-size: 24px;
    }

    p {
      color: $secondary-text-color;
      font-size: 17px;
      a {
        font-weight: 500;
      }
      b {
        font-weight: 500;
      }
    }

    .groups {
      padding-top: 5px;
      padding-bottom: $spacing-unit / 2 + 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      a {
        display: block;

        &:first-child {
          margin-top: 9px;
          margin-right: $spacing-unit;

          @include media-query($on-palm) {
            margin-top: 0;
            margin-right: 0;
            margin-bottom: $spacing-unit / 2;
          }
        }
      }
    }

    .images {
      a {
        img {
          margin-bottom: 5px;
          filter: grayscale(100%);
        }

        &:hover img {
          filter: none;
        }
      }
    }
  }

  .press {
    margin: 0 auto;
    text-align: center;
    max-width: 500px;

    h3 {
      font-size: 24px;
    }
    img {
      margin-bottom: $spacing-unit / 2;
    }
    p {
      font-size: 17px;
    }
  }

  .info {
    margin-bottom: $spacing-unit * 3;
    text-align: center;
    p {
      margin-bottom: 10px;
      color: $tertiary-text-color;
      font-size: 22px;
      a {
        color: $tertiary-text-color;
      }
    }
    @include media-query($on-palm) {
      margin-bottom: $spacing-unit * 2;
    }
  }

}

