/////////////
// PRICING //
/////////////
main .pricing {

  & > .header {
    margin: 0 auto;
    max-width: 550px;
    padding: 0 $spacing-unit / 2;
    text-align: center;

    h1 {
      padding-top: $spacing-unit;
      font-size: 40px;
    }
    p {
      font-size: 20px;
      color: $secondary-text-color;
      a {
        font-weight: 500;
      }
    }

    @include media-query($on-palm) {
      h1 {
        padding-top: $spacing-unit / 2;
        font-size: 34px;
      }
    }

    .announcement {
      $bkg-color: lighten(mix($brand-color, $grey-color-light, 10%), 15%);

      margin: $spacing-unit auto 0;
      display: inline-block;
      padding: 4px 11px;
      border: 1px solid lighten($brand-color, 25%);
      background-color: $bkg-color;
      border-radius: 3px;
      font-size: 15px;

      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-query($on-palm) {
          display: block;
        }
      }

      @include media-query($on-palm) {
        margin: $spacing-unit / 2 auto 0;
        padding: 5px $spacing-unit / 2;
        font-size: 14px;
      }

      .content {
        color: $secondary-text-color;
        i {
          margin-left: 3px;
        }
      }

      &:hover {
        background-color: darken($bkg-color, 3%);
        border-color: lighten($brand-color, 10%);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .table {
    margin: $spacing-unit + 10px auto 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    @include media-query($on-palm) {
      margin-top: 1.5 * $spacing-unit;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    & > .row {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    & > .row > div {
      margin: 0 $spacing-unit / 2 $spacing-unit;
      width: 280px;

      @include media-query($on-palm) {
        margin-left: 0;
        margin-right: 0;
        width: 280px;
      }

      .cost {
        border: 2px solid $grey-color-light;
        border-radius: 3px;

        .header {
          padding: 25px 0;
          margin-bottom: 0;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 1px;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.1;
          border-bottom: 2px solid $grey-color-light;
        }

        .numbers {
          text-align: center;
          padding-top: $spacing-unit * 2.5;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & div p:first-child {
            line-height: 1;
            font-size: 68px;

            & span {
              font-size: 42px;
              margin-right: 4px;
              font-weight: 400;
              vertical-align: 14px;
            }
          }

          & div p:nth-child(2) {
            color: $tertiary-text-color;
            font-size: 14px;
            margin-bottom: 10px;
          }

          & > p {
            color: $tertiary-text-color;
            font-size: 16px;
            margin-bottom: 6px;
          }
        }

        a.action {
          &.signup {
            @include action-button-brand;
          }
          &.start {
            @include action-button;
          }
          &.upgrade {
            @include action-button-brand;
          }

          &.start,
          &.upgrade,
          &.signup {
            margin: 0 $spacing-unit / 2 $spacing-unit / 2;
            padding: 12px;
            box-shadow: none;
            font-size: 14px;
            display: block;
            text-align: center;
          }
        }
      }

      &.startup .cost {
        border-color: $accent-color;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.21);

        .header {
          border-color: $accent-color;
        }
        .numbers p:first-child {
          font-weight: 500;
        }
      }

      &.enterprise .cost .numbers {
        p:first-child {
          font-weight: 500;
        }
      }
    }
  }

  .comparison {
    $cell-name-size: 220px;
    $cell-margin: 1.5 * $spacing-unit;

    margin: 0 auto;
    padding: 0 $spacing-unit / 2;

    @include media-query($on-palm) {
      overflow-x: auto;
    }

    & > .list {
      margin: 0 auto;
      padding: $spacing-unit / 2 + 7px $spacing-unit / 2 $spacing-unit / 2;
      min-width: 720px;
      max-width: 900px;
      border-radius: 3px;
      box-sizing: border-box;
      background-color: $divider-color-light;
      @include fix-scroll-clip($spacing-unit / 2);
    }

    h4 {
      margin-bottom: 1.5 * $spacing-unit;
      text-align: center;
      font-size: 28px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: $spacing-unit / 2;
      border-bottom: 1px solid $divider-color;

      & > div {
        flex: 1 1 auto;

        &:first-child {
          color: $tertiary-text-color;
          align-self: flex-end;
          flex: 0 0 $cell-name-size;
        }

        &+div {
          margin-left: $cell-margin;
        }
      }

      h6 {
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.1;
        font-family: $base-font-family;
      }
      .cost {
        margin-top: 5px;
        display: flex;
        align-items: baseline;
        p {
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
          }
          &:last-child {
            margin-left: 7px;
            color: $tertiary-text-color;
            font-size: 12px;
            line-height: 1.3;
          }
        }
      }
    }
    .section {
      & > .name {
        margin: $spacing-unit / 2 auto 0;
        font-size: 16px;
        padding-bottom: 10px;
        border-bottom: 1px solid $divider-color;
        font-weight: 600;
        text-transform: uppercase;
        font-family: $label-font-family;
        letter-spacing: 1px;
      }
      .feature {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-bottom: 1px solid $divider-color;
        padding: 10px 0;

        div {
          flex: 1 1 0;

          i.fa-times-circle {
            color: $grey-color-light;
          }
          i.fa-check-circle {
            color: $brand-color;
          }

          &.name {
            color: $tertiary-text-color;
            flex: 0 0 $cell-name-size;

            & > span {
              cursor: pointer;
              color: $tertiary-text-color;
              border-bottom: 1px dotted $grey-color-light;
              transition: .2s;

              &:hover {
                color: $secondary-text-color;
              }
            }
            & > i.fa {
              padding-left: 3px;
              font-size: 15px;
              color: $red-text-color;
            }
            & > .expand {
              cursor: pointer;
            }
            p {
              margin: 10px 0 0;
              display: none;
              font-size: 14px;
              line-height: 1.55;
              color: $text-color;

              a {
                white-space: nowrap;
              }

              i.fa-angle-right {
                font-size: 13px;
              }
            }

            &.open p {
              display: block;
            }
          }
          & > b {
            font-weight: 500;
          }
          &+div {
            margin-left: $cell-margin;
          }
        }
      }

      &:last-child {
        .feature:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }

  .concurrency {
    $padding: $spacing-unit / 2 + 5px;

    margin: $spacing-unit - 10px auto 0;
    padding: 0 $spacing-unit / 2;

    & > .container {
      margin: 0 auto;
      border-radius: 3px;
      padding: $padding $padding $padding + 5px $padding + 5px;
      background-color: $divider-color-light;
      box-sizing: border-box;
      max-width: 900px;
      display: flex;

      @include media-query($on-palm) {
        padding: $spacing-unit / 2;

        display: block;

        div.icon {
          margin-bottom: $spacing-unit / 2 - 2px;
        }
      }
    }

    h4 {
      font-family: $base-font-family;
      margin-bottom: 5px;
      font-size: 22px;
    }

    p {
      color: $secondary-text-color;

      &:last-child {
        margin-bottom: 0;
      }
    }

    div.icon {
      $size: 48px;

      margin-top: 5px;
      margin-right: $spacing-unit / 2;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: lighten($brand-color, 20%);
      text-align: center;
      flex: 0 0 $size;

      i {
        color: white;
        font-size: 21px;
        line-height: $size;
      }

      img {
        padding: 20px 0 18px;
      }
    }
  }

  .opening-action {
    margin-top: 1.5 * $spacing-unit;
    text-align: center;

    & > p {
      margin: 0 auto 20px;
      padding: 0 $spacing-unit;
      max-width: 520px;
      color: $secondary-text-color;
      font-size: 20px;

      b {
        color: $secondary-text-color;
        font-weight: 500;
      }
    }

    div.controls {
      padding: 0 $spacing-unit;
      a.sales {
        @include action-button;
        font-size: 18px;
        padding: 14px 26px;
        width: 222px;

        @include media-query($on-palm) {
        font-size: 18px;
          padding: 10px 22px;
        }
      }
    }
  }

  .addons {
    $icon-space: 88px;
    $price-width: 200px;
    $price-height: 70px;

    margin: 0 auto;
    max-width: 900px;

    & > hr {
      margin: 2 * $spacing-unit auto 2 * $spacing-unit - 10px;
    }

    h4 {
      margin-bottom: 10px;
      text-align: center;
      font-size: 28px;
    }

    & > p {
      margin: 0 auto 1.5 * $spacing-unit;
      padding: 0 $spacing-unit;
      color: $tertiary-text-color;
      text-align: center;
      font-size: 18px;
    }

    .list {
      padding: $spacing-unit / 2;
      background-color: $divider-color-light;
      border-radius: 6px;

      @include media-query($on-palm) {
        border-radius: 0;
      }

      .addon {
        $padding: $spacing-unit / 2 + 5px;
        $m-padding: 10px;

        padding: $padding $price-width + $padding $padding $icon-space;
        background-color: white;
        border-radius: 3px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08),
                    0 2px 4px -1px rgba(0, 0, 0, 0.03);

        @include media-query($on-palm) {
          padding-right: $m-padding;
          padding-bottom: $price-height + $m-padding;
        }

        .icon {
          $size: 96px;

          position: absolute;
          width: $size;
          height: $size;
          left: -20px;
          top: 50%;
          margin-top: - $size / 2;
          border-radius: 50%;
          text-align: center;
          background-color: lighten($accent-color, 34%);

          @include media-query($on-palm) {
            margin-top: - ( $size + $price-height ) / 2;
          }

          i.fa {
            color: $accent-color;
            font-size: 36px;
            line-height: $size;
          }
        }

        h6 {
          margin-bottom: 3px;
          font-family: $base-font-family;
          font-size: 20px;
          font-weight: 500;
        }

        & > p {
          margin: 0;
          color: $tertiary-text-color;
        }

        hr {
          margin: $padding + 3px 0 $spacing-unit / 2;
          border-color: $divider-color-light;
          border-width: 5px;
        }

        .features {
          span {
            color: $secondary-text-color;
            font-size: 14px;

            i.fa {
              margin-right: 3px;
              color: darken($accent-color, 15%);
            }

            &+span {
              margin-left: $spacing-unit;

              @include media-query($on-palm) {
                margin-left: 10px;
              }
            }
          }
        }

        .price {
          position: absolute;
          background-color: $background-color;
          width: $price-width;
          height: 100%;
          top: 0;
          right: 0;
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: center;

          @include media-query($on-palm) {
            bottom: 0;
            top: auto;
            width: 100%;
            height: $price-height;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: $m-padding $m-padding $m-padding;
          }

          .numbers {
            margin-top: $spacing-unit / 2;
            margin-bottom: 13px;
            text-align: center;

            & > span:first-child {
              margin-bottom: 0;
              line-height: 1;
              font-size: 32px;

              & span {
                font-size: 18px;
                margin-right: 3px;
                font-weight: 400;
                vertical-align: 8px;
              }
            }

            & > span:nth-child(2) {
              margin-bottom: 0;
              color: $tertiary-text-color;
              font-size: 14px;

              @include media-query($on-palm) {
                vertical-align: 6px;
              }
            }

            @include media-query($on-palm) {
              margin: 0 $spacing-unit / 2 0 0;
            }
          }

          a.action {
            @include action-button-brand;

            margin: 0 $spacing-unit;
            padding: 10px;
            box-shadow: none;
            font-size: 13px;
            display: block;
            text-align: center;

            @include media-query($on-palm) {
              margin: 0;
              flex: 1;
              max-width: 160px;
            }
          }

          a.action.learn {
            @include action-button-dark;
            padding: 10px;
            box-shadow: none;
            font-size: 13px;

            i.fa {
              font-size: 12px;
            }
          }

          p.email {
            margin: 5px 0 8px;
            font-size: 14px;
            text-align: center;
            color: $tertiary-text-color;

            &:nth-child(2) {
              margin-bottom: 0;
            }

            @include media-query($on-palm) {
              display: none;
            }
          }
        }

        &+.addon {
          margin-top: $spacing-unit / 2;
        }
      }
    }
  }

  .contact {
    margin-top: $spacing-unit;
    padding: 0 $spacing-unit / 2;
    text-align: center;

    p {
      margin-bottom: 0;
      font-size: 22px;
      color: $secondary-text-color;
    }

    div.controls {
      margin-top: $spacing-unit / 2 + 5px;
      a.sales {
        @include action-button;
        font-size: 18px;
        padding: 14px 26px;
        width: 222px;

        @include media-query($on-palm) {
        font-size: 18px;
          padding: 10px 22px;
        }
      }
    }
  }

  .faq {
    padding: 0 $spacing-unit / 2 2 * $spacing-unit;

    hr {
      margin: 2 * $spacing-unit auto 2 * $spacing-unit - 5px;
    }

    h4 {
      margin-bottom: 2 * $spacing-unit;
      text-align: center;
      font-size: 28px;
    }

    div.list {
      margin: 0 auto;
      max-width: 600px;

      ul {

        li {
          margin-bottom: $spacing-unit;

          span.fa-li {
            top: 4px;
          }
          p {
            color: $secondary-text-color;

            a {
              font-weight: 500;
            }

            &:nth-child(2) {
              margin-bottom: 10px;
              font-size: 20px;
              color: $text-color;
            }
          }
        }
      }
    }

    & > p {
      margin-top: 1.5 * $spacing-unit;
      color: $secondary-text-color;
      text-align: center;

      a {
        font-weight: 500;
      }
    }
  }
}

