.blog.post {
  margin: 0 auto;
  max-width: 900px;

  b, strong {
    font-weight: 500;
  }

  h1, h2, h3 {
    &:nth-child(n+2) {
      margin-top: $spacing-unit / 2 + 10px;
    }
  }
}

.blog.post .categories {
  margin: $spacing-unit - 5px 0 10px;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  background-color: $divider-color-light;

  .category {
    margin-right: 15px;
    padding: 15px 8px 15px 88px;
    background-color: white;
    border-radius: 4px;
    flex: 0 0 280px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
    transition: box-shadow ease-in-out .2s, transform ease-in-out .2s;

    .icon {
      $size: 95px;

      position: absolute;
      width: $size;
      height: $size;
      left: -20px;
      top: 50%;
      margin-top: - $size / 2;
      border-radius: 50%;
      text-align: center;

      i.fa {
        font-size: 36px;
        color: white;
        line-height: $size;
      }
    }
    h4 {
      margin-bottom: 6px;
      font-family: $base-font-family;
      font-weight: 500;
      font-size: 18px;
      color: $secondary-text-color;
    }
    p {
      margin-bottom: 3px;
      font-size: 14px;
      color: $tertiary-text-color;
      word-break: break-word;
    }
    p.footer {
      margin-bottom: 0;
      color: $grey-color-light;

      i.fa {
        margin-left: 2px;
        opacity: 0.7;
        font-size: 11px;
      }
    }

    &.news div.icon {
      background-color: rgba(217, 83, 79, 0.15);
      i.fa {
        color: rgba(217, 83, 79, 0.75);
      }
    }
    &.tips div.icon {
      background-color: lighten($accent-color, 35%);
      i.fa {
        color: $accent-color;
      }
    }
    &.community div.icon {
      background-color: #E3EFE5;
      i.fa {
        color: #9BB0AF;
      }
    }

    &:hover {
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      transform: translateY(-1px);

      h4 {
        color: $link-color;
      }
    }

    &:active {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
      transform: translateY(1px);
    }

    &:last-child {
      margin-right: 0;
    }

    @include media-query($on-palm) {
      margin: 0 0 10px;
      flex: 1 1 auto;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-query($on-palm) {
    padding: 10px;
    flex-direction: column;
  }
}

.blog.post .post-header {
  padding-bottom: $spacing-unit + 6px;
  margin-bottom: $spacing-unit + 20px;
  position: relative;
}
.blog.post .post-header .breadcrumb {
  margin-bottom: 7px;
  text-align: center;
  font-size: 18px;
  font-family: $base-font-family;

  & a:after {
    padding-left: 7px;
    color: $grey-color-light;
    content: "/\00a0";
  }
}
.blog.post .post-header .post-title {
  margin-bottom: 10px;
  text-align: center;
  font-size: 32px;
  line-height: 1.44;
}
.blog.post .post-header .post-title.post a {
  @extend %a-secondary;
}

.blog.post .post-header .post-info {
  text-align: center;
  font-size: 14px;
}
.blog.post .post-header .post-info .date {
  color: $tertiary-text-color;
}
.blog.post .post-header .post-info .separator {
  color: $grey-color-light;
}
.blog.post .post-header .post-info .twitter {
  $color: #1DA1F2;

  color: $color;

  &:hover {
    color: lighten($color, 13%);
  }
}
.blog.post .post-header img.post-author {
  $size: 48px;

  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: - $size / 2;
  margin-bottom: - $size / 2;
  border-radius: 100%;
  //filter: grayscale(100%);
}

$img-size: 36px;
$img-margin: 15px;

@mixin post-list {
  padding-left: 0;
  margin-left: $img-size + $img-margin;
  list-style-type: none;

  li {
    margin-bottom: $spacing-unit * 1.5;
    position: relative;
  }
  li h3 img.bullet {
    position: absolute;
    left: - ($img-size + $img-margin);
    width: $img-size;
    top: 1px;
    border-radius: 100%;
    //filter: grayscale(100%);
  }
  li .post-info {
    margin-top: $spacing-unit / 2;
    font-size: 14px;
  }
  li .post-info .category {
    @extend %a-tertiary;
    i.fa {
      margin-right: 2px;
      opacity: 0.8;
      font-size: 13px;
    }
  }
  li .post-info .date {
    color: $tertiary-text-color;
  }
  li .post-info .separator {
    color: $grey-color-light;
  }
}

.blog .post-content .twitter-share {
  margin-top: $spacing-unit / 2 + 5px;
  line-height: 2;
  span {
    margin-right: 4px;
  }
  a {
    padding: 6px 11px 5px;
    line-height: 1.6;
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    color: white;
    background-color: #1DA1F2;

    &:hover {
      background-color: #0C7ABF;
    }

    i.fa {
      margin-right: 4px;
      font-size: 15px;
      vertical-align: -1px;
    }
  }
}

.blog .post-list {
  @include post-list;
}

.blog.related-posts {
  margin: 0 auto;
  max-width: 800px;
  margin-top: 1.5 * $spacing-unit;
  padding-top: 1.5 * $spacing-unit;
  border-top: 1px solid $grey-color-light;
}
.blog.related-posts ul.post-list {
  @include post-list;
}
.blog.related-posts h5 {
  padding-bottom: 1.5 * $spacing-unit;
  margin-bottom: 1.5 * $spacing-unit;
  font-size: 26px;
  text-align: center;
  border-bottom: 1px solid $grey-color-light;
}
