@charset "utf-8";

// Our variables
$base-font-family:          Rubik, sans-serif;
$heading-font-family:       'Libre Baskerville', sans-serif;
$label-font-family:         'Exo 2', sans-serif;
$code-font-family:          'Source Pro', monospace;
$base-font-size:            17px;
$base-font-weight:          400;
$small-font-size:           $base-font-size * 0.875;
$base-line-height:          1.6;

$spacing-unit:              30px;

$text-color:                #2E2D33;
$secondary-text-color:      #5D5966;
$tertiary-text-color:  	    #908C99;
$quaternary-text-color:  	  #AEACB5;
$background-color:          #F9F9FA;
$hero-bkg-color:            lighten(#C7C6CC, 10%);
$brand-color:               #53437D;
$accent-color:              #FD9527;
$link-color:                #6A5CA2;
$red-text-color:            #D9534F;
$red-darker-color:          #BE2F2B;


$grey-color-light:          #C7C6CC;

$divider-color:             #E2E2E5;
$divider-color-light:       #EFEFF1;

// Width of the content     area
$content-width:             1280px;

$on-palm:                   600px;
$on-laptop:                 800px;


// Minima also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

// Import partials from the `minima` theme.
@import "theme";
@import "svg";

@mixin logo-font {
  font-family: $base-font-family;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
}

@mixin action-button {
  $bkg: $accent-color;
  $border: darken($bkg, 10%);

  color: white;
  border: 2px solid $border;
  background-color: $bkg;
  border-radius: 3px;
  font-size: 26px;
  padding: 14px 26px;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  font-family: $label-font-family;
  letter-spacing: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: background-color .3s ease-out, border .3s ease-out;

  &:hover {
    background-color: darken($bkg, 8%);
  }

  &:active {
    border-color: darken($border, 15%);
    background-color: darken($bkg, 15%);
  }

  @include media-query($on-palm) {
    font-size: 22px;
    padding: 10px 22px;
  }
}

@mixin action-button-dark {
  $bkg: #A29FAA;

  display: inline-block;
  background-color: $bkg;
  border: 2px solid darken($bkg, 5%);
  border-radius: 3px;
  padding: 4px 11px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-family: $label-font-family;
  letter-spacing: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: background-color .3s ease-out, border .3s ease-out;

  &:hover {
    background-color: darken($bkg, 5%);
  }

  &:active {
    border-color: darken($bkg, 30%);
    background-color: darken($bkg, 15%);
  }
}

@mixin action-button-brand {
  $bkg: lighten($brand-color, 10%);

  display: inline-block;
  background-color: $bkg;
  border: 2px solid darken($bkg, 5%);
  border-radius: 3px;
  padding: 4px 11px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-family: $label-font-family;
  letter-spacing: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: background-color .3s ease-out, border .3s ease-out;

  &:hover {
    background-color: darken($bkg, 5%);
  }

  &:active {
    border-color: darken($bkg, 30%);
    background-color: darken($bkg, 15%);
  }
}

// Import other pages
@import "blog";
@import "about";
@import "pricing";
@import "common-errors";

@mixin hero-graphic {
  $width: 785px;
  $height: 460px;

  position: relative;
  top: 13px;
  background: transparent url(/assets/lander/hero-macbook-template.png) no-repeat 0 0;
  background-size: $width $height;
  width: $width;
  height: $height;

  & > img {
    max-width: none;
  }

  .wallpaper {
    position: absolute;
    left: 105px;
    top: 29px;
    width: 577px;
    height: 360px;
    background: linear-gradient(to top, #FAE3CF, #C9C4DD);
  }

  .browser {
    $bar-height: 22px;
    $bar-height-m: 10px;
    $top-color: #E7E6E9;
    $bottom-color: #DCDBDE;

    width: 540px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04),
                0 2px 3px rgba(0, 0, 0, 0.03),
                0 3px 4px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    position: absolute;
    top: 46px;
    left: 123px;

    @include media-query($on-palm) {
      width: auto;
      position: relative;
    }

    .bar {
      width: 100%;
      background: linear-gradient(0deg, $bottom-color 0%, $top-color 100%);
      height: $bar-height;
      box-sizing: border-box;
      border: {
        bottom: 1px solid darken($bottom-color, 5%);
      }
      border-radius: 5px 5px 0 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include media-query($on-palm) {
        height: $bar-height-m;
      }

      span {
        $size: 7px;
        width: $size;
        height: $size;
        border-radius: $size / 2;
        $color: darken($bottom-color, 3%);
        background-color: $color;
        border: 1px solid darken($color, 2%);
        margin-right: 5px;
        display: block;
        box-sizing: border-box;

        @include media-query($on-palm) {
          $size: 8px;
          width: $size;
          height: $size;
          border-radius: $size / 2;
          margin-right: 6px;
        }

        &:first-child {
          margin-left: 8px;
        }
      }
    }
    img {
      box-sizing: border-box;
      border-radius: 0 0 5px 5px;
    }
  }
}

@mixin security-graphic {
  $pill-border-color: #9287AC;

  margin-top: 2 * $spacing-unit;
  position: relative;

  @include media-query($on-palm) {
    overflow-x: auto;
  }

  .graphic-container {
    margin: 0 auto;
    padding-bottom: 5px;
    position: relative;
    width: 680px;
  }

  .locked-area {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 3px dotted darken($background-color, 10%);
    text-align: center;

    i {
      $color: #E4C399;
      $border-color: darken($color, 5%);

      font-size: 180px;
      line-height: 300px;
      color: $color;
      text-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
      -webkit-text-stroke: 2px $border-color; 
      text-stroke: 2px $border-color; 
    }
  }

  .pill {
    padding: 3px 8px;
    line-height: 1.5;
    border-radius: 3px;
    font-family: $label-font-family;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid $pill-border-color;
    color: #74649A;
    white-space: nowrap;
    opacity: 0.5;
  }

  .user {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 150px;
    transform: translate(-50%, -50%);

    i {
      background-color: $background-color;
      padding: 10px;
      font-size: 36px;
      color: #DF9346;
    }

    .permissions {
      position: absolute;
      top: -18px;
      left: 60px;

      div {
        margin-bottom: 7px;

        &:nth-child(2) {
          opacity: 1;
          color: white;
          background-color: $pill-border-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .iam {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    transform: translate(-50%, -50%);

    img {
      background-color: $background-color;
      padding: 10px;
    }

    .roles {
      position: absolute;
      left: -78px;
      top: 9px;

      div {
        margin-bottom: 7px;
        text-align: right;

        &:nth-child(1) {
          opacity: 1;
          color: white;
          background-color: $pill-border-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@mixin ci-graphic {
  margin-top: 2 * $spacing-unit + 10px;
  display: flex;
  justify-content: center;
  overflow-x: auto;

  @include media-query($on-palm) {
    justify-content: flex-start;
    margin-bottom: 2 * $spacing-unit - 10px;
  }

  div.stage {
    padding: 0 1.5 * $spacing-unit;
    text-align: center;
    i {
      color: $tertiary-text-color;
      font-size: 72px;
    }
    p {
      margin-top: 7px;
      font-size: 14px;
      font-family: $label-font-family;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $secondary-text-color;
    }
  }
  .angle {
    flex: 0 0 24px;
    padding: 24px 0;
    opacity: 0.5;
    width: 24px;
    height: 24px;
    background: {
      position: center;
      repeat: no-repeat;
      image: $angle-icon;
    }
  }
}

@mixin mini-pipeline {

  & > .container {
    position: relative;
    text-align: center;
    width: 600px;

    .repo {
      display: inline-block;
      background-color: #4078C0;
      padding: 8px 16px 8px 12px;
      height: 40px;
      box-sizing: border-box;
      line-height: 1;
      border-radius: 20px;
      color: white;
      font-size: 15px;
      box-shadow: 0 1px 1px rgba(46, 45, 51, 0.02),
                  0 1px 2px rgba(46, 45, 51, 0.03),
                  0 2px 4px rgba(46, 45, 51, 0.04),
                  0 3px 6px rgba(46, 45, 51, 0.05);

      i.fa {
        margin-right: 4px;
        font-size: 24px;
        vertical-align: -4px;
      }
    }

    & > .connector {
      top: 19px;
      position: absolute;
      border-radius: 12px;
      border-style: dotted;
      border-color: $divider-color;
      z-index: -1;

      &.rectangle {
        left: 100px;
        width: 410px;
        height: 120px;
        border-width: 4px 4px 0 4px;
      }

      &.line {
        left: 50%;
        margin-left: -2px;
        width: 4px;
        height: 120px;
        border-width: 0 4px 0 0;
      }
    }
  }

  .table {
    text-align: left;
    margin-top: 1.5 * $spacing-unit;
    display: flex;
  }

  .part {
    margin-top: 10px;
    flex: 1 1 33%;
    width: 190px;

    &+.part {
      margin-left: $spacing-unit / 2;
    }
    
    & > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1px 9px;
    }
    & > .title h5 {
      margin: 0;
      font-size: 12px;
      font-family: $label-font-family;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 18px;
      color: $quaternary-text-color;
    }
    & > .title i.fa {
      color: #C7C6CC;
      line-height: 1;
      font-size: 14px;
    }
    
    .cell {
      margin-right: $spacing-unit;
      padding: 7px 7px 14px;
      width: 100%;
      background-color: white;
      border-radius: 4px;
      box-sizing: border-box;
      flex: 0 0 auto;
      box-shadow: 0 1px 1px rgba(46, 45, 51, 0.01),
                  0 1px 2px rgba(46, 45, 51, 0.01),
                  0 2px 4px rgba(46, 45, 51, 0.01),
                  0 3px 6px rgba(46, 45, 51, 0.03),
                  0 5px 10px rgba(46, 45, 51, 0.03),
                  0 10px 20px rgba(46, 45, 51, 0.03);
      
      &+.cell {
        margin-top: $spacing-unit / 2;
      }

      & > div.title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 7px 4px;
        margin-bottom: 9px;
        border-bottom: 1px solid $divider-color-light;
        height: 33px;
        box-sizing: border-box;

        .name {
          margin-bottom: -2px;
          font-size: 15px;
          color: $secondary-text-color;
        }
        .promote {
          font-size: 11px;
          color: $link-color;
          background-color: $divider-color-light;
          border-radius: 3px;
          padding: 7px 9px;
          line-height: 1;
        }
        .region {
          margin-left: 6px;
          color: $tertiary-text-color;
          font-size: 12px;
        }
      }

      &.prod > div.title {
        justify-content: flex-start;
        align-items: baseline;
      }
        
      .status {
        padding-left: 5px;
        display: flex;
        align-items: center;

        & > div {
          min-width: 0;
          flex-grow: 1;
        }
        & > div p {
          margin: 0;
        }
        & > div p+p {
          margin-top: 1px;
        }
        & > i.fa {
          margin-right: 8px;
          display: block;
          font-size: 30px;
          color: darken($accent-color, 12%);
        }
        & > i.fa-times-circle {
          color: #A35502;
        }
        & > i.fa-check-circle {
          color: #6fA492;
        }
        & > i.fa-minus-circle {
          color: #908C99;
        }
        & .id {
          color: $secondary-text-color;
        }
        & .date {
          margin-left: 3px;
          font-size: 12px;
          color: $quaternary-text-color;
        }
        & .line-2 {
          display: flex;
          align-items: center;
        }
        &.mini .line-2 {
          margin-top: 3px;
        }
        & .branch {
          margin-right: 5px;
          font-size: 12px;
          color: #908C99;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 0 1 auto;
        }
        & .branch i.fa {
          margin-right: 3px;
          opacity: 0.9;
        }
        & .commit {
          flex: 0 0 auto;
          font-size: 12px;
          color: #908C99;
          font-family: "Source Code Pro", monospace;
          border: 1px solid rgba(0, 0, 0, 0.07);
          border-radius: 3px;
          padding: 2px 4px;
          line-height: 1;
          display: inline-block;
          white-space: nowrap;
        }
      }
    }

    & > .footer {
      margin-top: 4px;
      text-align: center;
      .account {
        font-size: 12px;
        color: $quaternary-text-color;
      }
    }
  }
}

@mixin environments-graphic {
  $blend-height: 240px;
  $blend-bottom: 200px;

  height: 300px;
  margin-bottom: $blend-bottom;
  transform: translateY(40px);
  position: relative;

  @include media-query($on-palm) {
    height: auto;
    margin-bottom: 0;
    transform: none;
  }

  &::after {
    content: "";
    position: absolute;
    height: $blend-height;
    width: calc(100% + 40px);
    bottom: -1 * $blend-bottom;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-image: linear-gradient(0deg, $background-color 10%, rgba(249,249,250, 0));

    @include media-query($on-palm) {
      bottom: 0;
    }
  }

  & > .container {
    width: 500px;

    @include media-query($on-palm) {
      width: auto;
      max-width: 500px;
    }
  }
  
  .env {
    background-color: #FFFCFA;
    border-radius: 6px;
    padding: 8px 10px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 2px rgba(46, 45, 51, 0.02),
                0 2px 4px rgba(46, 45, 51, 0.03),
                0 3px 6px rgba(46, 45, 51, 0.03),
                0 4px 8px rgba(46, 45, 51, 0.03);
    
    &+.env {
      margin-top: 10px;
    }

    .col1 {
      min-width: 0;
    }
    .col1 {
      display: flex;
      align-items: center;
    }
    .col1 > b {
      margin-right: 11px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #D9534F;
        box-shadow: 0 0 4px 0 rgba(217, 83, 79, 0.4);
      }
      &.green {
        background-color: rgb(111, 164, 146);
        box-shadow: 0 0 4px 0 rgba(111, 164, 146, 0.4);
      }
      &.orange {
        background-color: $accent-color;
        box-shadow: 0 0 4px 0 rgba(253, 149, 39, 0.4);
      }
    }
    .col1 .copy p:first-child {
      margin-bottom: 1px;
      display: block;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 500;
      color: $secondary-text-color;
    }
    .col1 .copy p:last-child {
      margin-bottom: 0;
      font-size: 12px;
      color: #C7C6CC;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .col2 {
      margin-left: 10px;
      text-align: right;
    }
    .col2 > p {
      margin-bottom: 0;
    }
    .col2 > p:first-child {
      margin-bottom: 7px;
      display: inline-block;
      font-size: 10px;
      color: $secondary-text-color;
      font-family: $code-font-family;
      border-radius: 4px;
      padding: 2px 8px;
      background-color: #ffede1;
    }
    .col2 > p:last-child {
      font-size: 12px;
      color: $tertiary-text-color;
    }
  }

  @include media-query($on-palm) {
    .env:nth-child(5),
    .env:nth-child(6) {
      display: none;
    }
  }
  
}

@mixin incremental-deploys-graphic {
  $card-bkg: #FFFCFA;

  & > .container {
    display: flex;
    align-items: center;
    @include media-query($on-palm) {
      justify-content: center;
    }
  }

  @include media-query($on-palm) {
    .hide-mobile {
      display: none;
    }
  }

  .tag {
    position: relative;
    top: 18px;

    hr {
      margin: 0 0 0 50%;
      width: 50%;
      border-width: 4px;
      border-color: $divider-color;
    }
    .separator {
      margin-left: 50%;
      position: absolute;
      top: -3px;
      left: -6px;
      width: 10px;
      height: 10px;
      background-color: $divider-color;
      border-radius: 50%;
    }
    .copy {
      padding: 14px 10px 10px;
      display: inline-block;
      color: $tertiary-text-color;
      line-height: 1;
      font-size: 12px;
      white-space: nowrap;

      &.commit {
        font-size: 11px;
        font-family: $code-font-family;
      }
    }

    &.end {
      hr {
        margin-left: 0;
      }
      .copy {
        left: auto;
        right: 0;
      }
    }
  }

  .pointer {
    flex: 0 0 5px;
    position: relative;

    hr {
      margin: 0;
      border-width: 4px;
      border-color: $divider-color;
    }

    &.middle {
      flex: 0 0 $spacing-unit - 10px;
    }
  }

  .phase {
    margin: 0;
    padding: $spacing-unit / 2;
    background-color: $divider-color-light;
    flex: 0 0 160px;
    width: 160px;
    border-radius: 6px;

    .service {
      margin-bottom: $spacing-unit / 2;
      background-color: $card-bkg;
      border-radius: 6px;
      padding: 6px 10px 8px;
      box-shadow: 0 1px 2px rgba(46, 45, 51, 0.02),
                  0 2px 4px rgba(46, 45, 51, 0.03),
                  0 3px 6px rgba(46, 45, 51, 0.03),
                  0 4px 8px rgba(46, 45, 51, 0.03);

      &:last-child {
        margin-bottom: 0;
      }

      .status {
        padding-left: 7px;
        display: flex;
        align-items: center;

        i.fa {
          color: $grey-color-light;
        }

        .content {
          margin-left: 10px;

          p:first-child {
            margin-bottom: 1px;
            white-space: nowrap;
            font-weight: 500;
            color: $secondary-text-color;
          }
          p:last-child {
            margin-bottom: 0;
            font-size: 12px;
            color: $grey-color-light;
            white-space: nowrap;
          }
        }
      }

      &.deployed {
        padding-bottom: 10px;
        .status {
          i.fa {
            color: #6FA492;
            text-shadow: 0 0 3px rgba(111, 164, 146, 0.4);
          }
          .content {
            p:first-child {
              color: $text-color;
            }
            p:last-child {
              color: $tertiary-text-color;
            }
          }
        }
      }

      .lambdas {
        margin-top: 8px;
        border-radius: 3px;
        background-color: darken($card-bkg, 2%);

        hr {
          margin: 0;
          border-width: 2px;
          border-color: $card-bkg;
        }
      }

      .lambda {
        display: flex;
        align-items: center;
        padding: 7px 7px 7px 10px;

        b {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $divider-color;
        }

        .content {
          margin-left: 14px;
          margin-bottom: 0;
          white-space: nowrap;
          font-size: 14px;
          color: $grey-color-light;
        }

        &.deployed {
          b {
            background-color: #6FA492;
            box-shadow: 0 0 3px 0 rgba(111, 164, 146, 0.6);
            animation: phase-lambda-glow 1.5s infinite alternate;

            @keyframes phase-lambda-glow {
              from {
                opacity: 0.4;
              }
              to {
                opacity: 1;
              }
            }
          }
          .content {
            color: $text-color;
          }
        }
      }
    }

  }
}

@mixin ci-integration-graphic {
  .github-checks {
    width: 520px;
    border-radius: 6px;
    border: 1px solid #34D058;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(46, 45, 51, 0.01),
                0 2px 4px rgba(46, 45, 51, 0.01),
                0 4px 8px rgba(46, 45, 51, 0.03),
                0 8px 16px rgba(46, 45, 51, 0.05),
                0 16px 32px rgba(46, 45, 51, 0.07);

    .header {
      padding: $spacing-unit / 2;
      display: flex;

      .check-icon {
        margin-top: 2px;
        margin-right: 10px;
        height: 30px;
        width: 30px;
        background-color: #2EA44F;
        color: white;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 50%;
        box-sizing: border-box;

        svg {
          margin: 6px auto 0;
          display: block;
          fill: currentColor;
        }
      }

      .content {
        p:first-child {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 500;
        }
        p:last-child {
          margin-bottom: 0;
          font-size: 14px;
          color: $secondary-text-color;
        }
      }
    }

    .check {
      padding: 10px $spacing-unit / 2;
      border-top: 1px solid $divider-color;
      background-color: $divider-color-light;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content {
        display: flex;
        align-items: center;
      }

      svg {
        margin: 0 17px 0 7px;
        color: #22863A;
        fill: currentColor;
      }

      img {
        border-radius: 6px;
      }

      .name {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .status {
        margin-left: 8px;
        color: $tertiary-text-color;
        font-size: 13px;
      }

      .link {
        font-size: 12px;
        color: #0366D6;
        opacity: 0.8;
      }
    }

    .footer {
      border-top: 1px solid $divider-color;
      padding: $spacing-unit / 2;
      display: flex;
      align-items: center;

      svg {
        margin: 0 17px 0 7px;
        color: #22863A;
        fill: currentColor;
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
        color: $secondary-text-color;
      }
    }
  }
}

@mixin issues-graphic {
  $body-color: #FCF7F6;

  & > .container {
    width: 540px;
  }

  .header {
    padding: 0 10px 7px;
    display: flex;
    align-items: center;

    h5 {
      font-family: $label-font-family;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      color: $quaternary-text-color;
      margin: 0;
      font-size: 12px;

      &:nth-child(1) {
        flex: 1 1 auto;
      }
      &:nth-child(2) {
        flex: 0 0 auto;
        width: 130px;
        text-align: center;
      }
      &:nth-child(3) {
        margin-left: 10px;
        flex: 0 0 auto;
        width: 80px;
        text-align: center;
      }
    }
  }

  .body {
    background-color: $body-color;
    border-radius: 5px;
    padding: 10px 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                0 2px 6px rgba(0, 0, 0, 0.04),
                0 4px 12px rgba(0, 0, 0, 0.03),
                0 8px 24px rgba(0, 0, 0, 0.03),
                0 12px 36px rgba(0, 0, 0, 0.02);
  }
  
  .issue {
    display: flex;
    align-items: center;
    
    &+.issue {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid darken($body-color, 7%);
    }

    .error {
      flex: 1 1 auto;
      padding-right: 15px;

      h6 {
        margin-bottom: 2px;
        font-family: $base-font-family;
        font-weight: 500;
        color: $secondary-text-color;
        font-size: 15px;
      }
      .desc {
        margin-bottom: 1px;
        font-size: 13px;
        color: $tertiary-text-color;
      }
      .lambdas {
        margin-bottom: 0;
        font-size: 11px;
        color: $quaternary-text-color;
      }
    }
    .graph {
      $tip-color: rgba(72, 59, 107, 0.9);

      flex: 0 0 auto;
      width: 130px;
      height: 26px;
      position: relative;

      .tooltip-wrapper {
        position: absolute;
        top: 37px;
        right: -46px;
        z-index: 1;
      }

      .tip {
        width: 100px;
        position: relative;
        box-sizing: border-box;
        padding: 6px 6px 3px;
        background: $tip-color;
        border-radius: 5px;
      }

      .tip:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-bottom-color: $tip-color;
        border-top: 0;
        margin-left: -7px;
        margin-top: -7px;
      }

      .tip .duration {
        margin-bottom: 1px;
        padding-bottom: 1px;
        color: white;
        font-size: 9px;
      }
      .tip .duration p {
        margin: 0;
        white-space: nowrap;
        text-align: center;
      }
      .tip .count {
        margin: 0;
        padding-bottom: 3px;
        line-height: 1;
        text-align: center;
      }
      .tip .count label {
        margin-right: 6px;
        margin-bottom: 0;
        font-size: 9px;
        font-family: $label-font-family;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        line-height: 1.1;
        opacity: 0.8;
      }
      .tip .count span {
        font-family: $code-font-family;
        font-size: 9px;
        color: white;
        vertical-align: baseline;
      }

    }
    .events {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 80px;
      text-align: center;
      font-size: 14px;
      color: $tertiary-text-color;
    }
  }
  
}

@mixin pipeline-table {
  $border-width: 2px;

  div.table {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 540px;

    div {
      display: flex;
      align-items: center;
      height: 60px;
      box-sizing: border-box;
      border: {
        width: 0;
        style: dashed;
        color: lighten($grey-color-light, 10%);
      }

      // Status cells
      &.info {
        justify-content: center;
        padding: 10px;

        i {
          margin-right: 9px;
          font-size: 24px;

          &.fa-check-circle {
            color: #6FA492;
          }
          &.fa-times-circle {
            color: #CD5F4A;
          }
          &.fa-minus-circle {
            color: lighten($tertiary-text-color, 20%);
          }
        }

        span {
          color: lighten($tertiary-text-color, 15%);
          font-size: 12px;

          &.hide {
            visibility: hidden;
          }
        }

        &.promote {
          font-size: 13px;
          font-family: $label-font-family;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $brand-color;
        }
      }

      // First row
      &:nth-child(n):nth-child(-n + 6) {
        display: block;
        height: 80px;
        text-align: center;

        h6 {
          margin-top: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          font-family: $base-font-family;
          font-size: 14px;
          color: $secondary-text-color;
        }
        p {
          margin-bottom: 0;
        }
        span {
          margin-right: 10px;
          font-size: 12px;
          color: $tertiary-text-color;
          font-family: $code-font-family;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      // Bottom row
      &:nth-child(25),
      &:nth-child(27),
      &:nth-child(29) {
        height: 72px;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        color: $tertiary-text-color;
        img {
          margin-top: 4px;
          margin-bottom: 5px;
          display: block;
          opacity: 0.7;
        }
      }

      // Row dividers
      &:nth-child(n):nth-child(-n + 6),
      &:nth-child(n + 7):nth-child(-n + 12),
      &:nth-child(n + 13):nth-child(-n + 18),
      &:nth-child(n + 19):nth-child(-n + 24) {
        border-bottom: 1px solid lighten($grey-color-light, 10%);
      }

      // Horizontal dashed borders
      &:nth-child(7),
      &:nth-child(13),
      &:nth-child(19),
      &:nth-child(12),
      &:nth-child(18),
      &:nth-child(24) {
        border: {
          width: 0 $border-width;
        }
      }

      // Dev & prod top borders
      &:nth-child(1),
      &:nth-child(6) {
        border: {
          radius: 6px 6px 0 0;
          width: $border-width $border-width 0 $border-width;
        }
      }
      // Dev & prod bottom borders
      &:nth-child(25),
      &:nth-child(29) {
        border: {
          radius: 0 0 6px 6px;
          width: 0 $border-width $border-width $border-width;
        }
      }

      // QA top border
      &:nth-child(3) {
        border: {
          radius: 6px 0 0 0;
          width: $border-width 0 0 $border-width;
        }
      }
      // Staging top border
      &:nth-child(4) {
        border: {
          radius: 0 6px 0 0;
          width: $border-width $border-width 0 0;
        }
      }
      // QA horizontal border
      &:nth-child(9),
      &:nth-child(15),
      &:nth-child(21) {
        border: {
          width: 0 0 0 $border-width;
        }
      }
      // Staging horizontal border
      &:nth-child(10),
      &:nth-child(16),
      &:nth-child(22) {
        border: {
          width: 0 $border-width 0 0;
        }
      }
      // QA & staging bottom border
      &:nth-child(27) {
        border: {
          radius: 0 0 6px 6px;
          width: 0 $border-width $border-width $border-width;
        }
      }

      // Cell widths
      $cell-width: 130px;
      $cell-padding: 10px;

      &:nth-child(6n + 2),
      &:nth-child(6n + 5) {
        width: $cell-padding;
      }
      &:nth-child(6n + 1),
      &:nth-child(6n + 3),
      &:nth-child(6n + 4) {
        width: $cell-width;
      }
      &:nth-child(6n + 6),
      &:nth-child(29) {
        width: $cell-width;
      }
      &:nth-child(26),
      &:nth-child(28) {
        width: $cell-padding;
      }
      &:nth-child(27) {
        width: 2 * $cell-width;
      }
    }
  }
}

@mixin mono-repo-graphic {
  display: flex;
  justify-content: center;

  div.folder {
    box-sizing: border-box;

    & > span {
      margin-left: 7px;
      font-size: 20px;
      color: $tertiary-text-color;
      font-family: $code-font-family;

      i {
        margin-right: 7px;
        vertical-align: -1px;
        color: lighten($tertiary-text-color, 3%);
      }
    }

    div.content {
      border: 3px solid lighten(#F05952, 25%);
      border-radius: 6px;
      margin-top: 5px;
    }
  }

  div.root {
    display: inline-flex;
    flex-direction: column;

    & > div.content {
      padding: $spacing-unit - 10px $spacing-unit $spacing-unit;
      display: flex;
      justify-content: center;
    }
  }

  div.content > div.folder {
    width: 175px;
    margin-right: $spacing-unit;

    div.service {
      $padding: 12px;

      padding: $padding 0 0 $padding + 3px;
      color: $secondary-text-color;
      font-size: 16px;
      font-family: $code-font-family;

      img {
        margin-right: 6px;
        vertical-align: -3px;
      }

      &:last-child {
        padding-bottom: $padding;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin integrations-graphic {
  margin: 0 auto;
  padding: $spacing-unit * 3 1.5 * $spacing-unit 1.5 * $spacing-unit;

  @include media-query($on-palm) {
    overflow-x: auto;
    padding-top: 2 * $spacing-unit;
  }

  .services {
    margin: 0 auto;
    width: 700px;
    height: 200px;
    position: relative;

    div {
      background: transparent $hexagon-shape no-repeat 0 0;
      width: 88px;
      height: 88px;
      filter: drop-shadow( 0 7px 14px rgba(0, 0, 0, 0.1) );
      position: absolute;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        vertical-align: middle;
      }

      &:nth-child(4) {
        transform: scale(1.25) translate(-50%, -50%);
      }
      &:nth-child(7) {
        transform: scale(1.20) translate(-50%, -50%);
      }

      &:nth-child(6) {
        transform: scale(1.1) translate(-50%, -50%);
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(10),
      &:nth-child(11) {
        transform: scale(0.75) translate(-50%, -50%);
      }

      &:nth-child(2) {
        transform: scale(0.9) translate(-50%, -50%);
      }

      &:nth-child(1) {
        left: 0%;
        top: 23%;
      }

      &:nth-child(2) {
        left: 8%;
        top: 68%;
      }

      &:nth-child(3) {
        left: 17%;
        top: 14%;
      }

      &:nth-child(4) {
        left: 29%;
        top: 87%;
      }

      &:nth-child(5) {
        left: 36%;
        top: 24%;
      }

      &:nth-child(6) {
        left: 49%;
        top: 77%;
      }

      &:nth-child(7) {
        left: 59%;
        top: 11%;
      }

      &:nth-child(8) {
        left: 67%;
        top: 89%;
      }

      &:nth-child(9) {
        left: 77%;
        top: 29%;
      }

      &:nth-child(10) {
        left: 86%;
        top: 78%;
      }

      &:nth-child(11) {
        left: 92%;
        top: 10%;
      }
    }
  }
}

////////////
// HEADER //
////////////
main > .header {
  background-color: darken($hero-bkg-color, 10%);
  background-image: linear-gradient(to bottom, $background-color 0%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), $rain-pattern;
  text-align: center;

  .announcement {
    $bkg-color: lighten(mix($brand-color, $grey-color-light, 10%), 15%);

    margin: 0 auto;
    display: inline-block;
    padding: 4px 11px;
    border: 1px solid lighten($brand-color, 25%);
    background-color: $bkg-color;
    border-radius: 3px;
    font-size: 15px;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-query($on-palm) {
        display: block;
      }
    }

    @include media-query($on-palm) {
      display: block;
      padding: 5px $spacing-unit / 2;
      border-width: 1px 0;
      border-radius: 0;
    }

    .label {
      text-transform: uppercase;
      font-family: $label-font-family;
      letter-spacing: 1px;
      color: $text-color;
    }

    .content {
      color: $secondary-text-color;
      i {
        margin-left: 3px;
      }
    }

    &:hover {
      background-color: darken($bkg-color, 3%);
      border-color: lighten($brand-color, 10%);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .hero-container {
    overflow: hidden;

    @include media-query($on-palm) {
      overflow: initial;
      padding: 0 $spacing-unit / 2 $spacing-unit;
    }

    .hero {
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: $content-width;

      @include media-query($on-palm) {
        display: block;
      }

      .copy {
        flex: 0 0 50%;
        padding-left: $spacing-unit;

        @include media-query($on-palm) {
          margin-top: 0;
          padding: 0;
        }
      }

      .graphic-container {
        position: relative;
        margin: $spacing-unit 0 0;
        flex: 0 0 50%;
        height: 100%;

        @include media-query($on-palm) {
          display: none;
        }
      }
    }
  }

  h1 {
    margin-bottom: 10px;
    padding-top: 1.5 * $spacing-unit;
    letter-spacing: -1px;
    font-family: $base-font-family;
    font-size: 64px;
    line-height: 1.2;
    color: $text-color;
    text-align: left;
    font-weight: 500;
    max-width: 450px;

    @include media-query($on-palm) {
      font-size: 32px;
      padding-top: $spacing-unit;
      letter-spacing: normal;
      text-align: center;
    }
  }

  h3 {
    margin: 0;
    font-family: $base-font-family;
    color: $secondary-text-color;
    font-size: 20px;
    text-align: left;

    b {
      font-weight: 500;
      color: $text-color;
    }

    @include media-query($on-palm) {
      text-align: center;
    }
  }

  div.controls {
    margin-top: $spacing-unit - 5px;
    display: flex;
    align-items: flex-start;

    @include media-query($on-palm) {
      flex-direction: column;
      align-items: center;
    }

    a.signup {
      @include action-button;
      padding: 14px 26px;
      font-size: 18px;

      @include media-query($on-palm) {
        padding: 10px 22px;
        font-size: 18px;
      }
    }

    p {
      margin-top: 12px;
      margin-bottom: 0;
      text-align: left;
      color: $tertiary-text-color;
      font-size: 14px;

      @include media-query($on-palm) {
        text-align: center;
      }
    }

    a.customers {
      margin-left: $spacing-unit / 2;
      font-weight: 500;
      color: $link-color;
      line-height: 60px;

      i.fa {
        margin-left: 1px;
        font-size: 12px;
        vertical-align: 1px;
        opacity: 0.9;
        transition: 0.3s;
      }

      &:hover {
        color: $link-color;
        i.fa {
          transform: translateX(2px);
        }
      }

      @include media-query($on-palm) {
        margin-left: 0;
        margin-bottom: $spacing-unit / 2;
        order: -1;
        display: block;
        line-height: 1.6;
      }
    }
  }

  div.graphic {
    @include hero-graphic;
  }
}

////////////
// ACTION //
////////////
main > .action {
  position: relative;
  padding: 0 $spacing-unit / 2;

  .background {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    div {
      left: 0;
      position: absolute;
      width: 100%;
      height: 50%;
    }
    div:first-child {
      background-color: $hero-bkg-color;
      background-image: $rain-pattern;
      top: 0;
    }
    div:last-child {
      top: 50%;
      height: 50%;
      border-top: 2px solid darken($hero-bkg-color, 5%);
    }
  }

  .controls {
    margin: 0 auto;
    padding: $spacing-unit + 10px 2 * $spacing-unit $spacing-unit + 20px;
    position: relative;
    border-radius: 3px;
    background-color: lighten($background-color, 1%);
    box-shadow: 0 15px 35px rgba(0, 0, 0, .1), 0 5px 15px rgba(0, 0, 0, .07);
    max-width: 640px;
    box-sizing: border-box;
    text-align: center;

    @include media-query($on-palm) {
      padding: $spacing-unit $spacing-unit / 2 + 5px $spacing-unit + 10px;
    }

    p {
      padding-bottom: $spacing-unit / 2;
      color: $tertiary-text-color;
      font-size: 20px;
      b {
        color: $secondary-text-color;
        font-weight: 500;
      }

      @include media-query($on-palm) {
        font-size: 16px;
      }
    }

    a.signup {
      margin-right: $spacing-unit / 2 + 1px;
      @include action-button;
      padding: 14px 26px;
      width: 222px;

      @include media-query($on-palm) {
        font-size: 22px;
        padding: 10px 22px;
      }
    }
    a.demo {
      @include action-button-brand;
      padding: 14px 26px;
      width: 222px;
    }
    a.signup, a.demo {
      font-size: 18px;
    }

    @include media-query($on-palm) {
      a.signup {
        margin-right: 0;
        margin-bottom: $spacing-unit / 2;
      }
    }
  }
}

//////////////
// FEATURES //
//////////////
main > .features {
  @include media-query($on-palm) {
    padding-bottom: $spacing-unit * 2;
  }

  & > h4.opening {
    margin: 2 * $spacing-unit auto 0;
    padding: 0 $spacing-unit / 2;
    max-width: 670px;
    line-height: 1.8;
    text-align: center;
    font-size: 24px;
    font-family: $base-font-family;
    color: $secondary-text-color;
    a {
      font-weight: 500;
    }

    @include media-query($on-palm) {
      margin: $spacing-unit auto 0;
      font-size: 20px;
    }
  }


  & > hr {
    margin: $spacing-unit * 2.5 auto;
    max-width: 800px;
    border-color: darken($hero-bkg-color, 10%);

    @include media-query($on-palm) {
      & {
        margin: $spacing-unit auto $spacing-unit + 5px;
      }
    }
  }

  .feature {
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 3.5 * $spacing-unit;
    display: flex;
    max-width: $content-width;
    align-items: center;
    justify-content: flex-end;

    &:nth-child(even) {
      justify-content: flex-start;
    }

    @include media-query($on-palm) {
      display: block;
      padding-bottom: $spacing-unit;
      border-bottom: 1px solid $divider-color;

      &+.feature {
        padding-top: $spacing-unit;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    & > div.body {
      margin: 0 2 * $spacing-unit;
      min-width: 400px;

      @include media-query($on-palm) {
        margin: 0 $spacing-unit / 2;
        min-width: auto;
      }

      & > div.header {
        text-align: left;

        .icon {
          margin-bottom: 10px;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: lighten($brand-color, 50%);
          text-align: center;

          i.fa {
            color: $brand-color;
            font-size: 20px;
            line-height: 48px;

            &.fa-code-fork { margin-left: 1px; }
            &.fa-dashboard { margin-top: -1px; }
          }
        }

        h2 {
          margin: 0;
          font-size: 38px;

          @include media-query($on-palm) {
            font-size: 30px;
          }

          span {
            margin-left: 10px;
            vertical-align: 7px;
            padding: 3px 6px;
            border-radius: 3px;
            background-color: $accent-color;
            color: white;
            text-transform: uppercase;
            font-size: 10px;
            font-family: $label-font-family;
            line-height: 1;
          }
        }
        p {
          margin: 0;
          font-size: 20px;
          color: $tertiary-text-color;
          line-height: 1.8;
        }
      }

      .copy {
        margin: $spacing-unit / 2 0 10px;
        color: $secondary-text-color;
        line-height: 1.7;
      }

      .learn {
        i.fa {
          font-size: 13px;
          opacity: 0.9;
        }
      }

    }

    &:nth-child(even) > div.body {
      order: -1;

      @include media-query($on-palm) {
        margin: 0 $spacing-unit / 2;
      }
    }

    div.graphic {
      margin-left: 2 * $spacing-unit;

      @include media-query($on-palm) {
        margin: 0;
        padding: 0 $spacing-unit / 2 $spacing-unit + 10px;
        overflow-x: auto;

        &.environments {
          overflow-x: hidden;
        }

        & > div {
          @include fix-scroll-clip($spacing-unit / 2);
        }
      }
    }

    &:nth-child(even) > div.graphic {
      margin-left: 0;
      margin-right: 2 * $spacing-unit;

      @include media-query($on-palm) {
        margin: 0;
      }
    }

    div.graphic.mini-pipeline       { @include mini-pipeline; }
    div.graphic.issues              { @include issues-graphic; }
    div.graphic.environments        { @include environments-graphic; }
    div.graphic.incremental-deploys { @include incremental-deploys-graphic; }
    div.graphic.ci-integration      { @include ci-integration-graphic; }
  }

  div.action {
    text-align: center;
    padding: 0 $spacing-unit;

    p {
      margin: 0 auto $spacing-unit;
      max-width: 560px;
      color: $secondary-text-color;
      font-size: 20px;

      b {
        color: $secondary-text-color;
        font-weight: 500;
      }
    }
    a.signup {
      @include action-button;
      padding: 14px 0;
      width: 222px;
      font-size: 18px;

      @include media-query($on-palm) {
        width: 260px;
        font-size: 22px;
        padding: 10px 20px;
      }
    }
    a.plans {
      margin-right: $spacing-unit / 2 + 1px;
      @include action-button-dark;
      padding: 14px 0;
      width: 222px;
      font-size: 18px;

      @include media-query($on-palm) {
        width: 260px;
        font-size: 22px;
        padding: 10px 20px;
      }
    }

    @include media-query($on-palm) {
      a.plans {
        margin-right: 0;
        margin-bottom: $spacing-unit / 2;
      }
    }
  }
}

/////////////
// REVIEWS //
/////////////
main > .testimonials {
  padding-top: $spacing-unit - 5px;
  background-color: darken($brand-color, 5%);
  border-top: 2px solid $accent-color;
  color: $background-color;

  @include media-query($on-palm) {
    padding-top: $spacing-unit - 5px;
  }

  div.customers {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    @include media-query($on-palm) {
      justify-content: flex-start;
      padding: 0 $spacing-unit / 2;
    }

    a {
      margin: $spacing-unit $spacing-unit - 10px;
      display: block;
      flex-shrink: 0;
      box-sizing: border-box;

      &.kovi { margin-right: 12px; }
      &.freelancer {  }
      &.kasa { }
      &.mozilla { margin-right: 16px; }
      &.splunk { margin-right: 14px; }
      &.essensys { }
      &.shyft { }

      @include media-query($on-palm) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
          @include fix-scroll-clip($spacing-unit / 2);
        }
      }
    }
  }

  div.list {
    margin: 0 auto;
    max-width: $content-width;
    display: flex;

    @include media-query($on-palm) {
      display: block;
    }
  }

  div.testimonial {
    flex: 1 1 50%;
    padding: $spacing-unit $spacing-unit $spacing-unit + 2px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.2);

      @include media-query($on-palm) {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }

    @include media-query($on-palm) {
      display: block;
      padding: 0 0 $spacing-unit;
      margin: $spacing-unit;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & > p {
      line-height: 1.7;
      font-family: $heading-font-family;
      font-size: 20px;
      text-align: left;
      color: $background-color;

      @include media-query($on-palm) {
        font-size: 18px;
      }
    }

    & > div.person {
      margin-top: $spacing-unit / 2;
      display: flex;
      align-items: center;

      @include media-query($on-palm) {
        margin-top: 10px;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08),
                    0 1px 2px rgba(0, 0, 0, 0.06),
                    0 2px 4px rgba(0, 0, 0, 0.05);
      }
      div.info {
        margin-left: $spacing-unit / 2;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;

        &:last-child {
          font-size: 14px;
          opacity: 0.8;

          a {
            color: white;
          }
        }
      }
    }
  }
}

//////////////
// TUTORIAL //
//////////////
main > .tutorial {
  border-top: 2px solid darken($hero-bkg-color, 5%);
  overflow: hidden;

  @include media-query($on-palm) {
    padding: $spacing-unit * 2 $spacing-unit / 2 0;
  }

  .tutorial-container {
    margin: 0 auto;
    max-width: $content-width;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 415px;

    @include media-query($on-palm) {
      display: block;
      height: auto;
    }
  }

  .copy {
    padding: 0 $spacing-unit * 1.5 + 5px 0 $spacing-unit;
    box-sizing: border-box;
    flex: 0 0 55%;
    font-size: 18px;
    line-height: 1.7;

    @include media-query($on-palm) {
      text-align: center;
      flex: auto;
      padding: 0;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 28px;
    }
    p {
      color: $secondary-text-color;
      a, b {
        font-weight: 500;
      }
    }
    .action {
      margin-top: $spacing-unit / 2 - 4px;
      @include action-button-dark;
      font-family: $base-font-family;
      text-transform: none;
      font-weight: 400;
      letter-spacing: 0;
      border-width: 1px;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .browser-container {
    position: relative;
    margin: 0;
    flex: 0 0 45%;
    height: 100%;

    @include media-query($on-palm) {
      margin-top: $spacing-unit / 2;
      height: auto;
      flex: auto;
    }
  }
  .browser {
    $bar-height: 30px;
    $bar-height-m: 20px;
    $top-color: #E7E6E9;
    $bottom-color: #DCDBDE;

    width: 640px;
    box-shadow: 0 -1px 3px 0 rgba(46, 45, 51, 0.03),
                0 -2px 4px 0 rgba(46, 45, 51, 0.02),
                0 -3px 6px 0 rgba(46, 45, 51, 0.01),
                0 0 40px 20px rgba(46, 45, 51, 0.05),
                0 0 60px 30px rgba(46, 45, 51, 0.08);
    border-radius: 4px;
    position: absolute;
    bottom: -1 * $spacing-unit;
    left: 0;

    @include media-query($on-palm) {
      width: auto;
      min-width: 440px;
      position: relative;
    }

    .bar {
      width: 100%;
      background: linear-gradient(0deg, $bottom-color 0%, $top-color 100%);
      height: $bar-height;
      box-sizing: border-box;
      border-bottom: 1px solid darken($bottom-color, 5%);
      border-radius: 4px 4px 0 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include media-query($on-palm) {
        height: $bar-height-m;
      }

      span {
        $size: 12px;
        width: $size;
        height: $size;
        border-radius: $size / 2;
        $color: darken($bottom-color, 3%);
        background-color: $color;
        border: 1px solid darken($color, 5%);
        margin-right: 8px;
        display: block;
        box-sizing: border-box;

        @include media-query($on-palm) {
          $size: 8px;
          width: $size;
          height: $size;
          border-radius: $size / 2;
          margin-right: 6px;
        }

        &:first-child {
          margin-left: 10px;
        }
      }
    }
    img {
      box-sizing: border-box;
      border-radius: 0 0 4px 4px;
    }
  }

}

////////////////////
// CLOSING ACTION //
////////////////////
main > .closing-action {
  border-top: 2px solid darken($hero-bkg-color, 5%);
  padding: $spacing-unit * 2 + 10px 0 $spacing-unit * 3;
  text-align: center;

  & > p {
    margin: 0 auto $spacing-unit;
    padding: 0 $spacing-unit;
    max-width: 520px;
    color: $tertiary-text-color;
    font-size: 20px;

    b {
      color: $secondary-text-color;
      font-weight: 500;
    }
  }

  div.controls {
    padding: 0 $spacing-unit;
    a.signup {
      @include action-button;
      padding: 14px 26px;
      width: 222px;

      @include media-query($on-palm) {
        padding: 10px 22px;
      }
    }
    a.plans {
      margin-right: $spacing-unit / 2 + 1px;
      @include action-button-brand;
      padding: 14px 26px;
      width: 222px;

      @include media-query($on-palm) {
        padding: 10px 22px;
      }
    }
    a.signup, a.plans {
      font-size: 18px;
    }

    @include media-query($on-palm) {
      a.plans {
        margin-right: 0;
        margin-bottom: $spacing-unit / 2;
      }
    }
  }

  .platforms {
    margin-top: $spacing-unit;
    text-align: center;

    div {
      img {
        opacity: 0.33;
        vertical-align: middle;
        margin: 0 6px;
      }
    }
  }

  .divider {
    margin-top: $spacing-unit - 10px;
    margin-bottom: $spacing-unit - 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacing-unit / 2;
    max-width: 340px;

    div {
    position: relative;
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit;
    }
    hr {
      margin: 0;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
    }

    span {
      color: $grey-color-light;
      padding: 0 7px;
      background-color: $background-color;
      display: inline-block;
      position: relative;
    }
  }

  .updates {
    padding: 0 $spacing-unit / 2;
    color: $tertiary-text-color;
  }

  .button {
    margin-bottom: $spacing-unit / 2 - 4px;
    @include action-button-dark;
    font-family: $base-font-family;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0;
    border-width: 1px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.notfound {
  text-align: center;
  padding: 100px $spacing-unit / 2 100px;
  font-size: 18px;
}

/////////////////////
// ONBOARDING PAGE //
/////////////////////
main > .concierge {

  .header {
    margin: 0 auto;
    padding: 0 $spacing-unit / 2;
    max-width: 570px;
    text-align: center;

    h1 {
      padding-top: $spacing-unit * 2;
      font-family: $base-font-family;
      font-size: 36px;
      line-height: 1.4;
      color: $text-color;
    }
    p {
      font-family: $base-font-family;
      color: $tertiary-text-color;
      font-size: 20px;
    }

    @include media-query($on-palm) {
      h1 {
        padding-top: $spacing-unit / 2;
        font-size: 34px;
      }
    }
  }

  hr {
    margin: $spacing-unit * 3 auto;
    max-width: 400px;
    border-color: darken($hero-bkg-color, 10%);

    @include media-query($on-palm) {
      margin: $spacing-unit * 1.5 auto;
    }
  }

  .what {
    padding: 0 $spacing-unit / 2;
    p {
      margin: 0 auto;
      font-size: 24px;
      max-width: 760px;
      text-align: center;
      color: $secondary-text-color;

      b {
        font-weight: 500;
        color: $text-color;
      }
    }
  }

  .comparison {
    background-color: $background-color;
    background-image: $diagonal-pattern;
    margin: $spacing-unit * 3 0;
    padding: $spacing-unit * 2 $spacing-unit / 2;
    border-top: 2px solid lighten($brand-color, 30%);
    border-bottom: 2px solid lighten($brand-color, 30%);
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-query($on-palm) {
      margin: $spacing-unit * 1.5 0;
      padding: $spacing-unit - 5px $spacing-unit / 2;
      flex-direction: column;
    }

    .new {
      $size: 32px;
      $margin: 12px;

      background-color: $background-color;
      background-image: $graph-pattern;
      border-radius: 4px;
      box-shadow: 0 15px 35px rgba(0, 0, 0, .1), 0 5px 15px rgba(0, 0, 0, .07);
      border: 6px solid #FDFDFE;
      max-width: 480px;
      padding: $spacing-unit + 5px $spacing-unit $spacing-unit;

      @include media-query($on-palm) {
        margin-bottom: $spacing-unit;
        padding: $spacing-unit / 2 + 5px $spacing-unit / 2 $spacing-unit / 2;
      }

      ul {
        padding-left: 0;
        margin-left: $size + $margin;
        margin-bottom: 0;
        list-style-type: none;

        li {
          position: relative;
          margin-bottom: $spacing-unit + 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      span {
        position: absolute;
        top: 0;
        left: - ($size + $margin);
        width: $size;
        height: $size;
        border-radius: $size / 2;
        background-color: lighten($accent-color, 10%);
        color: white;
        text-align: center;;
        font-weight: 500;
        line-height: $size;
        font-size: 15px;
      }

      h6 {
        margin-bottom: 5px;
        font-size: 18px;
        font-family: $label-font-family;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      p {
        margin-bottom: 0;
        color: lighten($text-color, 8%);
        b {
          color: $text-color;
          font-weight: 500;
        }
      }
    }

    .old {
      margin-left: $spacing-unit;

      h6 {
        margin-bottom: 10px;
        margin-left: 8px;
        font-size: 16px;
        font-family: $label-font-family;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $tertiary-text-color;
      }

      ul {
        margin-bottom: 0;
      }

      li {
        margin-bottom: 10px;
        vertical-align: middle;
        color: $tertiary-text-color;
        i {
          color: lighten($tertiary-text-color, 15%);
          line-height: 21px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }

  .closing {
    padding: 0 $spacing-unit / 2 $spacing-unit * 3;
    text-align: center;

    @include media-query($on-palm) {
      padding-bottom: $spacing-unit * 2;
    }

    p {
      margin: 0 auto;
      max-width: 680px;
      font-size: 22px;
      color: $secondary-text-color;

      &:first-child {
        margin-bottom: 3px;
      }
    }

    div.controls {
      margin-top: $spacing-unit;

      a.contact {
        margin-right: $spacing-unit / 2 + 1px;
        @include action-button-dark;
        padding: 14px 26px;
        width: 222px;

        @include media-query($on-palm) {
          font-size: 22px;
          padding: 10px 22px;
        }
      }
      a.demo {
        @include action-button;
        padding: 14px 26px;
        width: 222px;
      }
      a.contact, a.demo {
        font-size: 18px;
      }

      @include media-query($on-palm) {
        a.contact {
          margin-right: 0;
          margin-bottom: $spacing-unit / 2;
        }
      }
    }

    div.platforms {
      margin-top: $spacing-unit + 10px;
      text-align: center;

      div {

        img {
          opacity: 0.33;
          vertical-align: middle;
        }

        &:first-child {
          img {
            margin: 0 9px;

            @include media-query($on-palm) {
              margin: 0 5px;
            }
          }
        }

        &:last-child {
          margin: $spacing-unit 0 0;
        }
      }
    }
  }

}
